/* Colors */
:root{
    --primaryTextColor: #2a3b4c;
    --textSecondaryColor: #5e6166;  
    --midnight-blue: #2a3b4c;
    --bright-blue: #00c0fc;
    --white: #ffffff;
    --cyan: #00BCD4;
    --dark-blue: #114989;
    --medium-blue: #365677;
    --light-blue: #cdecf8;
    --hover-blue: #114989;
    --active-blue: #163868;
    --input-focus-blue: #BFDBFE;
    --hover-red: #EE4646;
    --active-red: #D64040;
    --gray-100: #5e6166;
    --gray-80: #8C9096;
    --gray-60: #c0c4ca;
    --gray-40: #E0E4EA;
    --gray-20: #F3F5F8;
    --blue-100: #0a7acc;
    --blue-80: #3B95D6;
    --blue-60: #6CAFE0;
    --blue-40: #9DCAEB;
    --blue-20: #CEE4F5;
    --dark-green-100: #6ea318;
    --green-100: #85c51f;
    --green-80: #9DD14C;
    --green-60: #B6DC79;
    --green-40: #CEE8A5;
    --green-20: #E7F3D2;
    --red-100: #ff4c4c;
    --red-80: #FF7070;
    --red-60: #FF9494;
    --red-40: #FFB7B7;
    --red-20: #FFDBDB;
    --orange-100: #dc6803;
    --orange-80: #E38635;
    --orange-60: #EAA468;
    --orange-40: #F1C39A;
    --orange-20: #F8E1CD;
    --dark-yellow-100: #ffc02b;
    --yellow-100: #fec84b;
    --yellow-80: #FED36F;
    --yellow-60: #FEDE93;
    --yellow-40: #FFE9B7;
    --yellow-20: #FFF4DB;
    --background-blue: #EAF7FC;
    --background-darker-blue: #20272e;
    --navigation-hover-blue: #2f3b46;
    --navigation-active-blue: #3e4f5e;
    --background-gray: #dfe4ea;
    --background-light-gray: #f3f4f5;
    --background-dark-blue: #2a343d;
    --filter-border: #C0C4CA80;
    --filter-focus-background: #f0f3f8;
    --gibson-font: 'Gibson', 'sans-serif';
    --modal-box-shadow:  0px 10px 10px 0px #2A3B4C15;
}