/* You can add global styles to this file, and also import other style files */

/* Prime NG styles */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "styles/fonts.scss";
@import "styles/variables.scss";

BODY {
    padding: 0;
    margin: 0;
}