@font-face {
    font-family: 'Gibson';
    font-weight: 400;
    src: url('/assets/fonts/gibson/30D562_4_0.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Gibson';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/gibson/30D562_6_0.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Gibson';
    font-weight: 600;
    src: url('/assets/fonts/gibson/30D562_5_0.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Gibson';
    font-style: italic;
    font-weight: 600;
    src: url('/assets/fonts/gibson/30D562_2_0.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Gibson';
    font-weight: 800;
    src: url('/assets/fonts/gibson/30D562_3_0.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Gibson';
    font-style: italic;
    font-weight: 800;
    src: url('/assets/fonts/gibson/30D562_1_0.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Gibson';
    font-weight: 300;
    src: url('/assets/fonts/gibson/30D562_7_0.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Gibson';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/gibson/30D562_0_0.woff2') format('woff2');
  }